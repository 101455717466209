import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

const ServicesPage = () => {
  const data = useStaticQuery(graphql`{
  banner: file(relativePath: {eq: "2xbanner7.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image2: file(relativePath: {eq: "2ximg-66.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image3: file(relativePath: {eq: "2ximg-67.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image4: file(relativePath: {eq: "2ximg-68.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image5: file(relativePath: {eq: "2ximg-69.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image6: file(relativePath: {eq: "2ximg-610.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  image7: file(relativePath: {eq: "2ximg-611.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`)

  return (
    <Layout>
      <SEO title="服务范畴" />
      <div className={`mainContent`}>
        <Header
          activePage="services"
          color={{ bg: "#ffffffb3", text: "#000" }}
        />
        <div className="banner" data-bg-color="#ffffffb3" data-color="#000000">
          <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
        </div>
        <div
          className="point-bar s2 js-viewport-animation"
          style={{ backgroundColor: "#006572", color: "#fff" }}
          data-bg-color="#ffffff"
          data-color="#000000"
        >
          <div className="list js_anchor cn-point">
            <a href="#row01">
              <span>01</span>森唯服务范畴
            </a>
            <a href="#row02">
              <span>02</span>森唯项目管理
            </a>
            s<div className="point"></div>
          </div>
        </div>
        <div
          id="row01"
          className="js-viewport-animation row06"
          data-bg-color="#fff"
          data-color="#3e3a39"
        >
          <div className="wp1180">
            <h3 className="tit01 tal">
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 405.66 49.1"
              >
                <g>
                  <g>
                    <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
                    <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
                    <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
                    <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
                    <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
                    <path d="M192.7,37a11.51,11.51,0,0,1-1.11,5.13,11.79,11.79,0,0,1-3,3.82,12.62,12.62,0,0,1-4.37,2.36,17.34,17.34,0,0,1-5.25.79,19.34,19.34,0,0,1-7.68-1.49,16.27,16.27,0,0,1-5.76-4l1.08-1a15.54,15.54,0,0,0,5.64,3.91,17.52,17.52,0,0,0,6.65,1.31,15,15,0,0,0,4.72-.73,12.78,12.78,0,0,0,4-2.1,9.79,9.79,0,0,0,2.7-3.38,10.33,10.33,0,0,0,1-4.62,8.31,8.31,0,0,0-1.08-4.39,10.73,10.73,0,0,0-2.8-3.06,17.31,17.31,0,0,0-3.92-2.14c-1.47-.57-2.94-1.09-4.43-1.56Q176.27,25,173.94,24a15.07,15.07,0,0,1-4-2.42,9.68,9.68,0,0,1-2.61-3.44,11.53,11.53,0,0,1-.93-4.87,10.92,10.92,0,0,1,1.12-5,10.79,10.79,0,0,1,3-3.7A13.94,13.94,0,0,1,175,2.25a17.57,17.57,0,0,1,5.32-.8,16.63,16.63,0,0,1,6.53,1.31,12.86,12.86,0,0,1,5.07,3.73l-1.09,1A12.07,12.07,0,0,0,186.24,4a14.54,14.54,0,0,0-6-1.24,15.82,15.82,0,0,0-4.49.64,12.54,12.54,0,0,0-4,1.94,10,10,0,0,0-2.86,3.28,9.56,9.56,0,0,0-1.08,4.65,9.39,9.39,0,0,0,.95,4.43,9.78,9.78,0,0,0,2.55,3.09,14.54,14.54,0,0,0,3.6,2.1q2,.83,4.11,1.53,2.87.95,5.35,1.94a19.27,19.27,0,0,1,4.36,2.39,10,10,0,0,1,2.93,3.41A10.37,10.37,0,0,1,192.7,37Z" />
                    <path d="M204,47.83V2.73h26.31V4H205.44V23.81h23.45v1.28H205.44V46.56h25.93v1.27Z" />
                    <path d="M267.24,47.83,253.6,25.09H243V47.83h-1.4V2.73h12.48a29.74,29.74,0,0,1,5,.44,12.78,12.78,0,0,1,4.56,1.69A9.76,9.76,0,0,1,267,8.27a10.94,10.94,0,0,1,1.27,5.61A10.42,10.42,0,0,1,267,19.16a10,10,0,0,1-3.12,3.41,12.82,12.82,0,0,1-4.23,1.88,19.11,19.11,0,0,1-4.56.57l13.7,22.81Zm-.39-33.95A9.53,9.53,0,0,0,265.71,9a8.6,8.6,0,0,0-3-3,12.89,12.89,0,0,0-4.33-1.57,28.6,28.6,0,0,0-5-.44H243V23.88h11q6.24,0,9.52-2.61A8.9,8.9,0,0,0,266.85,13.88Z" />
                    <path d="M292.21,47.83H290l-17-45.1h1.53l16.44,43.83h.13L307.88,2.73h1.53Z" />
                    <path d="M317.3,47.83V2.73h1.41v45.1Z" />
                    <path d="M361.93,47.42A24.55,24.55,0,0,1,353,49.1a23.54,23.54,0,0,1-9.3-1.78,21.65,21.65,0,0,1-12-12.49A26.52,26.52,0,0,1,330,25.28a26.57,26.57,0,0,1,1.69-9.56,22.05,22.05,0,0,1,4.75-7.54,21.78,21.78,0,0,1,7.29-4.94A23.68,23.68,0,0,1,353,1.45a24,24,0,0,1,8.19,1.44A17.38,17.38,0,0,1,368,7.25l-.89,1a15.41,15.41,0,0,0-3-2.45,19.28,19.28,0,0,0-3.56-1.75,20.29,20.29,0,0,0-3.79-1,22.85,22.85,0,0,0-3.7-.32,21.6,21.6,0,0,0-8.82,1.75,20.32,20.32,0,0,0-6.82,4.81A21.81,21.81,0,0,0,333,16.46a24.65,24.65,0,0,0-1.56,8.82A25.08,25.08,0,0,0,333,34.1a21.25,21.25,0,0,0,4.36,7.17,20.32,20.32,0,0,0,6.82,4.81A21.75,21.75,0,0,0,353,47.83a22.5,22.5,0,0,0,8.31-1.62,16.48,16.48,0,0,0,6.79-4.88l1,1A17.46,17.46,0,0,1,361.93,47.42Z" />
                    <path d="M378.33,47.83V2.73h26.31V4H379.73V23.81h23.44v1.28H379.73V46.56h25.93v1.27Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <div className="list04 list04-cn">
              <div className="list04-item wow fadeIn" id="projectConsultancy">
                <div className="title js-text-animation">项目咨询</div>
                <div className="info">
                  <div className="js-text-animation">• 选址分析</div>
                  <div className="js-text-animation">• 租区选择</div>
                  <div className="js-text-animation">• 平面空间测试</div>
                  <div className="js-text-animation">• 工程量评估</div>
                  <div className="js-text-animation">• 成本估算</div>
                  <div className="js-text-animation">• 时间规划</div>
                </div>
              </div>
              <div className="list04-item wow fadeIn" id="interioDesign">
                <div className="title js-text-animation">室内设计</div>
                <div className="info">
                  <div className="js-text-animation">• 平面规划</div>
                  <div className="js-text-animation">• 概念设计</div>
                  <div className="js-text-animation">• 深化设计</div>
                  <div className="js-text-animation">• 3D效果图</div>
                  <div className="js-text-animation">• 材料选择</div>
                  <div className="js-text-animation">• 机电设计</div>
                  <div className="js-text-animation">• 配饰设计</div>
                  <div className="js-text-animation">• 报批图</div>
                  <div className="js-text-animation">• 施工图</div>
                  <div className="js-text-animation">• 竣工图</div>
                </div>
              </div>
              <div className="list04-item wow fadeIn" id="projectManage">
                <div className="title js-text-animation">项目管理及施工</div>
                <div className="info">
                  <div className="js-text-animation">• 大厦报审</div>
                  <div className="js-text-animation">• 政府报审</div>
                  <div className="js-text-animation">• EHS管理</div>
                  <div className="js-text-animation">• 空气质量管理</div>
                  <div className="js-text-animation">• 进度管理</div>
                  <div className="js-text-animation">• 质量管理</div>
                  <div className="js-text-animation">• 施工安全管理</div>
                  <div className="js-text-animation">• 变更管理</div>
                  <div className="js-text-animation">• 成本控制</div>
                  <div className="js-text-animation">• 采购管理</div>
                  <div className="js-text-animation">• 风险控制</div>
                  <div className="js-text-animation">• 交付管理</div>
                </div>
              </div>
              <div className="list04-item wow fadeIn" id="facilityMaintenance">
                <div className="title js-text-animation">设施维护</div>
                <div className="info">
                  <div className="js-text-animation">• 定期巡检</div>
                  <div className="js-text-animation">• 报修响应</div>
                  <div className="js-text-animation">• 设备维护</div>
                  <div className="js-text-animation">• 局部改造</div>
                  <div className="js-text-animation">• 延保服务</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="row02"
          className="js-viewport-animation row07"
          data-bg-color="#e0fbf9"
          data-color="#000"
        >
          <div className="wp1180">
            <h3 className="tit01 tal">
              <svg
                className="pic"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 933.29 49.74"
              >
                <g>
                  <g>
                    <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
                    <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
                    <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
                    <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
                    <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
                    <path d="M190.61,13.29a12,12,0,0,1-1.1,5.25,11,11,0,0,1-2.94,3.8,13.18,13.18,0,0,1-4.34,2.31,17.19,17.19,0,0,1-5.27.79H164.85v23h-1.46V1.67H176.1a24.21,24.21,0,0,1,5.1.56,14.72,14.72,0,0,1,4.67,1.88,10.43,10.43,0,0,1,3.41,3.57A10.93,10.93,0,0,1,190.61,13.29Zm-1.46,0A9.9,9.9,0,0,0,188,8.34a9.17,9.17,0,0,0-2.91-3.17,12.55,12.55,0,0,0-4-1.72,19,19,0,0,0-4.4-.52H164.85V24.18H177a16.42,16.42,0,0,0,4.37-.59,11.23,11.23,0,0,0,3.91-1.91,10,10,0,0,0,2.78-3.4A11,11,0,0,0,189.15,13.29Z" />
                    <path d="M227.89,48.42,213.71,24.84h-11V48.42h-1.45V1.67h13a31.28,31.28,0,0,1,5.24.46,13.47,13.47,0,0,1,4.73,1.75,10.08,10.08,0,0,1,3.41,3.54,11.33,11.33,0,0,1,1.32,5.81,10.94,10.94,0,0,1-1.25,5.48,10.61,10.61,0,0,1-3.25,3.53,13.31,13.31,0,0,1-4.4,1.94,19.61,19.61,0,0,1-4.74.6l14.24,23.64Zm-.4-35.19a9.8,9.8,0,0,0-1.19-5.05A8.86,8.86,0,0,0,223.15,5a13.51,13.51,0,0,0-4.5-1.62,29,29,0,0,0-5.2-.46H202.72V23.59h11.46q6.48,0,9.9-2.71A9.2,9.2,0,0,0,227.49,13.23Z" />
                    <path d="M286.55,25A26.25,26.25,0,0,1,284.73,35a23.4,23.4,0,0,1-5,7.79,22.91,22.91,0,0,1-7.65,5.12,25.83,25.83,0,0,1-19.2,0,22.91,22.91,0,0,1-7.65-5.12,23.4,23.4,0,0,1-5-7.79A26.25,26.25,0,0,1,238.35,25a26.2,26.2,0,0,1,1.82-9.93,23.4,23.4,0,0,1,5-7.79,23,23,0,0,1,7.62-5.12,26,26,0,0,1,19.26,0,23,23,0,0,1,7.62,5.12,23.4,23.4,0,0,1,5,7.79A26.2,26.2,0,0,1,286.55,25Zm-1.45,0a25.1,25.1,0,0,0-1.66-9.14,22.47,22.47,0,0,0-4.67-7.43,21.82,21.82,0,0,0-7.18-5,23.86,23.86,0,0,0-18.28,0,21.82,21.82,0,0,0-7.18,5,22.47,22.47,0,0,0-4.67,7.43A24.89,24.89,0,0,0,239.8,25a25.32,25.32,0,0,0,1.63,9.15,22.15,22.15,0,0,0,4.6,7.42,21.27,21.27,0,0,0,7.15,5,24.52,24.52,0,0,0,18.54,0,21.37,21.37,0,0,0,7.15-5,22.15,22.15,0,0,0,4.6-7.42A25.53,25.53,0,0,0,285.1,25Z" />
                    <path d="M311,45.87q-3,3.74-9,3.73a11.6,11.6,0,0,1-7.08-2.14,11.2,11.2,0,0,1-4-5.19l1.25-.59A11.49,11.49,0,0,0,296,46.47a10.1,10.1,0,0,0,6,1.81,11.23,11.23,0,0,0,4.84-1,8.56,8.56,0,0,0,3.24-2.68,11.47,11.47,0,0,0,1.85-4.06,20.61,20.61,0,0,0,.6-5.12V1.67H314v34Q314,42.15,311,45.87Z" />
                    <path d="M327.74,48.42V1.67h27.35V3H329.2V23.52h24.36v1.32H329.2V47.09h26.95v1.33Z" />
                    <path d="M397.7,48a25.67,25.67,0,0,1-9.24,1.75,24.66,24.66,0,0,1-9.67-1.85A22.43,22.43,0,0,1,366.31,35,27.35,27.35,0,0,1,364.56,25a27.33,27.33,0,0,1,1.75-9.9A22.36,22.36,0,0,1,378.82,2.2,24.72,24.72,0,0,1,388.46.35,25,25,0,0,1,397,1.84a18.09,18.09,0,0,1,7,4.52l-.93,1.06A15.92,15.92,0,0,0,400,4.87a20.88,20.88,0,0,0-3.71-1.81A21.7,21.7,0,0,0,392.3,2a23.35,23.35,0,0,0-3.84-.33,22.57,22.57,0,0,0-9.17,1.82,21.24,21.24,0,0,0-7.09,5,22.89,22.89,0,0,0-4.57,7.43A25.51,25.51,0,0,0,366,25a26,26,0,0,0,1.59,9.15,22,22,0,0,0,4.54,7.42,21,21,0,0,0,7.08,5,22.78,22.78,0,0,0,9.24,1.82,23.46,23.46,0,0,0,8.64-1.69,17,17,0,0,0,7-5l1.06,1A18.07,18.07,0,0,1,397.7,48Z" />
                    <path d="M425.94,3V48.42h-1.46V3h-16V1.67H441.9V3Z" />
                    <path d="M509.57,48.42V3.32h-.26l-20.73,45.1h-1.19L466.73,3.32h-.2v45.1h-1.46V1.67h2.59L488.05,46.3l20.4-44.63H511V48.42Z" />
                    <path d="M559.37,48.42,553.8,35H526.59l-5.76,13.41h-1.59L539.57,1.67h1.85L561,48.42ZM540.43,2.6,527.12,33.76h26.22Z" />
                    <path d="M604.46,48.42,570.82,3.52h-.2v44.9h-1.46V1.67h2.06l33.7,44.83h.2V1.67h1.46V48.42Z" />
                    <path d="M654.92,48.42,649.35,35H622.14l-5.76,13.41h-1.59L635.12,1.67H637L656.5,48.42ZM636,2.6,622.67,33.76h26.22Z" />
                    <path d="M695.83,48.48A34.58,34.58,0,0,1,686,49.74a26,26,0,0,1-10.15-1.92A23.4,23.4,0,0,1,668,42.57a23,23,0,0,1-5-7.85,26.92,26.92,0,0,1-1.76-9.81,26.43,26.43,0,0,1,1.79-9.8,23,23,0,0,1,5-7.79,22.77,22.77,0,0,1,7.71-5.12A26,26,0,0,1,685.71.35a25.52,25.52,0,0,1,8.74,1.45,23.85,23.85,0,0,1,7,4l-1.06,1.06a15.13,15.13,0,0,0-3.08-2.21A23.26,23.26,0,0,0,693.62,3a24.73,24.73,0,0,0-4-1,25,25,0,0,0-4-.33,23.63,23.63,0,0,0-9.4,1.82,21.5,21.5,0,0,0-7.25,5,22.14,22.14,0,0,0-4.64,7.4,25,25,0,0,0-1.62,9A26,26,0,0,0,664.32,34a21.83,21.83,0,0,0,4.6,7.49,21.49,21.49,0,0,0,7.35,5.06,24.9,24.9,0,0,0,9.83,1.85,33,33,0,0,0,8.48-1,38.85,38.85,0,0,0,6.82-2.51V26.56H689.22V25.31h13.64v20.4A36.23,36.23,0,0,1,695.83,48.48Z" />
                    <path d="M716.17,48.42V1.67h27.34V3H717.62V23.52H742v1.32H717.62V47.09h27v1.33Z" />
                    <path d="M799.73,48.42V3.32h-.26l-20.73,45.1h-1.19L756.89,3.32h-.2v45.1h-1.46V1.67h2.59L778.21,46.3l20.4-44.63h2.58V48.42Z" />
                    <path d="M815.23,48.42V1.67h27.34V3H816.68V23.52h24.37v1.32H816.68V47.09h27v1.33Z" />
                    <path d="M889.59,48.42,856,3.52h-.2v44.9h-1.46V1.67h2.06l33.7,44.83h.2V1.67h1.46V48.42Z" />
                    <path d="M917.33,3V48.42h-1.45V3h-16V1.67h33.37V3Z" />
                  </g>
                </g>
              </svg>
            </h3>
            <div className="pic04 pic04-cn wow fadeIn">
              <div className="pic">
                <GatsbyImage image={data.image2?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="tit js-text-animation">信息化管理</div>
                <div className="desc js-text-animation">
                  管理是一个系统工程,它对施工工期、质量、费用和安全的控制起到至关重要的作用。森唯在项目实施中，使用内部工程管理系统，对项目的全生命周期进行信息化管理。所有专业均按照系统流程有序协同，高效运转，确保项目如期保质交付。信息化管理为森唯创建智慧工地提供了基础。
                </div>
              </div>
            </div>
            <ul className="list06 list06-cn">
              <li>
                <div className="item wow fadeIn item1">
                  <div className="tit js-text-animation">源头控制</div>
                  <div className="sub js-text-animation">绿色选材</div>
                  <div className="desc">
                    <p className="js-text-animation">无醛板材</p>
                    <p className="js-text-animation">无醛腻子</p>
                    <p className="js-text-animation">水性木器漆</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item wow fadeIn item2" data-wow-delay=".2s">
                  <div className="tit js-text-animation">环保施工</div>
                  <div className="sub js-text-animation">过程控制</div>
                  <div className="desc">
                    <p className="js-text-animation">通风管理</p>
                    <p className="js-text-animation">防尘降噪</p>
                    <p className="js-text-animation">污染管理</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item wow fadeIn item3" data-wow-delay=".4s">
                  <div className="tit js-text-animation">动态监测</div>
                  <div className="sub js-text-animation">数据控制</div>
                  <div className="desc">
                    <p className="js-text-animation">可视化</p>
                    <p className="js-text-animation">时时更新</p>
                    <p className="js-text-animation">尽在掌控</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="item wow fadeIn item4" data-wow-delay=".6s">
                  <div className="tit js-text-animation">安心搬入</div>
                  <div className="sub">
                    <p className="js-text-animation">无需治理</p>
                    <p className="js-text-animation">持续监测</p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="pic04 pic04-s2-cn s2 wow fadeIn">
              <div className="pic">
                <div className="desc">
                  <span className="js-text-animation">
                    人们将近90%的时间都是在建筑中度过，作为人们居住、工作和生活的载体，其内部环境很大程度上直接或间接的影响着人体健康。森唯作为建筑装饰行业倡导绿色健康的先行企业，通过亲身实践，将环境质量控制贯穿全过程。
                  </span>
                  <br />
                  <span className="js-text-animation">
                    为更多的使用者提供可降低人类健康风险的空间环境，助力健康理念在建筑室内中的应用和推广。同时森唯也希望为每一位客户提供健康、舒适且卓有成效的工作环境，让员工健康的工作是我们的责任。
                  </span>
                </div>
              </div>
              <div className="txt">
                <div className="tit js-text-animation">绿色办公</div>
              </div>
            </div>
            <div className="pic04 pic04-cn wow fadeIn">
              <div className="pic">
                <GatsbyImage image={data.image3?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="tit js-text-animation">
                  全程可视化动态监测空气、现场施工。
                </div>
                <div className="desc js-text-animation">
                  360度动态镜头连结移动端，24小时监测现场甲醛、VOC、PM2.5、温度、湿度。
                </div>
                {/* <a className="link-btn">see more about</a> */}
              </div>
            </div>
            <div className="pic04 pic04-s3-cn s3 wow fadeIn">
              <div className="pic">
                <GatsbyImage image={data.image4?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="tit js-text-animation">绿色选材，集中采购</div>
                <div className="desc js-text-animation">
                  森唯注重品质管理，大到主材小到基层板、涂料、五金及电线，均由公司统一采购及配送，产品必须符合森唯对健康环保的高标准要求。去掉中间商，与厂家直接达成战略采购，为客户提供品质保障的同时节约成本。
                </div>
              </div>
            </div>
            <div className="pic04 pic04-s4-cn s4 wow fadeIn">
              <div className="pic">
                <GatsbyImage image={data.image5?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="tit js-text-animation">安全管理</div>
                <div className="desc js-text-animation">
                  进行安全管理的目的是预防、消灭事故，防止或消除事故伤害，保护劳动者的安全与健康。
                  森唯生产同时管安全，不仅是对各级领导人员明确安全管理贡任，同时，也向一切与生产有关的机构、人员，明确了业务范围内的安全管理责任。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="js-viewport-animation row08"
          data-bg-color="#1d1d1d"
          data-color="#fff"
        >
          <div className="wp1180">
            <div className="pic04 s3 pic04-s3-cn">
              <div className="pic">
                <GatsbyImage image={data.image6?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="tit js-text-animation">
                  森唯自有家具生产中心
                </div>
                <div className="desc js-text-animation">
                  位于河北唐山，占地面积20000平米，拥有先进的生产线，烤漆房配有专业水循环洁净设备，极大地提升了工艺品质。{" "}
                  <br />
                  除隔墙及必须在现场完成的工作内容以外，其他饰面及固定家具均在工厂完成制作加工，现场完成组装，不仅避免了工地现场加工造成的污染，同时压缩了施工工期，专业的生产车间及设备也从根本上改善了产品质量。
                </div>
                {/* <a className="link-btn js-text-animation">更多工厂介绍</a>  */}
              </div>
            </div>
            <div className="pic04 s5 pic04-s5-cn">
              <div className="pic">
                <GatsbyImage image={data.image7?.childImageSharp?.gatsbyImageData} alt="" />
              </div>
              <div className="txt">
                <div className="tit js-text-animation">售后服务</div>
                <div className="desc">
                  <div className="js-text-animation">• 2年保修</div>
                  <div className="js-text-animation">
                    • 24小时内对客户所提维修需求做出响应
                  </div>
                  <div className="js-text-animation">
                    • 紧急情况 4小时 内专人到场
                  </div>
                  <div className="js-text-animation">• 客户确认维修项目</div>
                  <div className="js-text-animation">
                    • 售后经理将每季度与客户联系了解使用情况
                  </div>
                  <div className="js-text-animation">
                    • 保修期满后可继续提供售后维护服务
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ServicesPage
